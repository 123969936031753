<style scoped>
    @import url("../../../styles/entrada/carousel/carouselSmall.scss");
</style>

<template>

    <div class="box_carouselSmall hidden-md-and-up">

        <!-- SMALL -->
        <div 
        class="painelCarousel_s animate__animated animate__pulse"
        v-for="(el,i) in itemsCarousel" :key="i"
        :visibleSlide="visibleSlide"
        v-show="visibleSlide == i"
        >
            <div class="txtCarousel mt-2 mb-2">
                <span class="titTxt">{{ el.tit }}</span>
                <span class="subTxt">{{ el.sub }}</span>
            </div>

            <div class="imgCarousel" @click="callContacts('/Contactos')">
                <!-- resolução da imagem -->
                <!-- base x altura => a base precisa ser maior que a altura, pois a base é maior na renderização -->
                <img 
                class=""
                :style="{'width':'100%','max-width':'400px','height':'100%','max-height':'400px'}"
                :src="el.img"
                /><!-- ,'border':'1px solid red' -->
            </div>
        </div>
    
    </div>

</template>

<script>
export default {

    props:['itemsCarousel'],
    
    data() {
        return {
            // carousel control.
            polling:null, // obj carousel.
            flagStopCarousel: false, // parar carousel com scroll.
            visibleSlide: 0, // id posição da lista de imagens carousel.
            aux_index: 0, // id aux para a var visibleSlide.
        }
    },

    methods: {
        callContacts(rota) { this.$router.push(rota) },
        
        contadorCarousel() {
            
            this.polling = window.setInterval(() => {

                if (this.flagStopCarousel == true) {

                    this.aux_index = this.visibleSlide
                    this.visibleSlide = this.aux_index // STOP
                }

                else { // Keep on

                    if(this.visibleSlide >= this.itemsCarousel.length - 1) {
                    
                        this.visibleSlide = 0 // RESET
                    }
                    else {
                    
                        this.visibleSlide++
                    }
                }

            }, 8000);
        }
    },

    beforeDestroy () {
      clearInterval(this.polling)
    },

    created(){
        //
        this.contadorCarousel()
    }
}
</script>
