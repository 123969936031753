<style scoped>
    @import url("../../../styles/entrada/carousel/carouselEntrada.scss");
</style>

<template>

    <div class="box_carouselEntrada">

        <ElCarouselSmall :itemsCarousel="itemsCarousel" />

        <ElCarouselLarge :itemsCarousel="itemsCarousel" />
    
    </div>

</template>

<script>
import ElCarouselSmall from './ElCarouselSmall'
import ElCarouselLarge from './ElCarouselLarge'

export default {

    components:{ElCarouselSmall, ElCarouselLarge},
    
    data() {
        return {
            itemsCarousel: [
                {
                    tit:'Letreiro Luminoso Sob Medida',
                    sub:'Uma nova forma de apresentar a marca do seu negócio.',
                    img:require('../../../assets/carousel/letr2.jpg'),
                },
                {
                    tit:'Led Neon Personalizado',
                    sub:'Já imaginou ter a chance de captar a atenção dos seus clientes de uma forma diferente todos os meses?',
                    img:require('../../../assets/carousel/neons2.jpg'),
                },
            ],
        }
    },

    methods: {
        //
    },

    created(){
        //
    }
}
</script>
