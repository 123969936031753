<style scoped>
    @import url("./styles/app.scss");
</style>

<template>
  <v-app class="box_app">

    <MenuApp />

    <v-main>
      <router-view/>
    </v-main>

    <Rodape />
    
  </v-app>
</template>

<script>
import MenuApp from './components/MenuApp'
import Rodape from './components/Rodape'

export default {
  name: 'App',

  components:{MenuApp,Rodape},

  data: () => ({
    //
  }),
};
</script>
