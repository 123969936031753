<style scoped>
    @import url("../../styles/quemSomos/quemSomos.scss");
</style>

<template>

    <div class="box_quemSomos" :style="{backgroundImage:'url(' + require('../../assets/imgsInit/capaPink4c.png') + ')'}">

        <!-- BTN BACK TO MAIN -->
        <div class="btnBack" @click="callRota('/')">
            <v-icon 
            large 
            @mouseover="colorBtnBack = '#F7D72E'" 
            @mouseleave="colorBtnBack = '#4B94C0'"
            :style="{'color':colorBtnBack}"
            >
                mdi-arrow-left-circle-outline
            </v-icon>
        </div>

        <!-- TIT -->
        <div class="box_tit_QS">
            <span>Quem Somos</span>
        </div>

        <!-- CONTENT -->
        <div class="box_content_QS">

            <div class="txt_QS">
                <span class="mb-3">{{ txt1 }}</span>
                <span>{{ txt2 }}</span>
            </div>

            <div class="img_QS">
                <v-img class="img_logoMenuApp" 
                :src="require('../../assets/imgsInit/loraQS_2.jpeg')" 
                :style="{'border-radius':'48px'}"
                />
            </div>

        </div>
    
    </div>

</template>

<script>
export default {
    
    data() {
        return {
            //
            colorBtnBack:'#4B94C0',

            txt1:'Sempre que se deparou com a reflexão sobre as estratégias a serem implementadas para atrair uma clientela mais ampla, mesmo que se tratasse apenas de captar um olhar ou chamar a atenção, é possível que não tenha encontrado soluções satisfatórias. Agora, apresenta-se uma propícia oportunidade: a UP NEON oferece-lhe a chance de recuperar a atenção desejada. Uma oportunidade para adquirir a visibilidade necessária a fim de impulsionar suas vendas.',
            txt2:'Confie em nossa parceria para esta nova empreitada. Estamos preparados para proporcionar-lhe um serviço de iluminação totalmente personalizado. Aquela expressão ou design que anteriormente apenas residia em suas ideias pode agora manifestar-se diante de sua loja ou irradiar-se internamente, complementando seus produtos de maneira distintiva.',
        }
    },

    methods:{
        callRota(rota) { this.$router.push(rota) }
    },

    created() { window.scrollTo(0,0) },
}
</script>
