import Vue from 'vue'
import VueRouter from 'vue-router'
import Entrada from '../components/entrada/Entrada.vue'
import QuemSomos from '../components/quemSomos/QuemSomos.vue'
import Contactos from '../components/contactos/Contactos.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Entrada',
    component: Entrada
  },
  {
    path: '/QuemSomos',
    name: 'QuemSomos',
    component: QuemSomos
  },
  {
    path: '/Contactos',
    name: 'Contactos',
    component: Contactos
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
