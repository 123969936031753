<style scoped>
    @import url("../styles/rodape.scss");
</style>

<template>

    <div class="box_rodape">

        <!--<span class="el_parceiros_tit mt-4">Parceiros UPinC</span>

        <div class="el_parceiros_rodape mt-2 mb-4">
            <div class="parceiro" v-for="(el,i) in li" :key="i">
                <v-img class="img_parc" :style="{'border-radius':'90px'}" :src="el.img" />
            </div>
        </div>

        <div class="el_frase_rodape">
            
            <v-icon small :style="{'margin':'4px'}">
                mdi-circle
            </v-icon>

            geral@upinc.pt
            
        </div>
        
        <div class="el_frase_rodape">
            
            <v-icon small :style="{'margin':'4px'}">
                mdi-circle
            </v-icon>

            <span>Rua António Augusto Aguiar, 60, Covilhã, Portugal</span>
        </div>-->

        <v-icon :style="{'margin':'30px','color':'#F7D72E'}">mdi-target</v-icon>

        <div class="box_call">
            <span class="el_call" v-for="(el,i) in liCall" :key="i">{{ el }}</span>            
        </div>

        <div class="el_frase_rodape">
            
            <!--<v-icon small :style="{'margin':'4px'}">
                mdi-circle
            </v-icon>-->

            <span>Rua Capelo Ivens, 86, Fundão, Portugal</span>            
        </div>

        <span 
        class="el_end_rodape mt-4"
        >
            <v-icon small :style="{'margin':'4px'}">
                mdi-copyright
            </v-icon>

            2024 Desenvolvimento UPinC
        </span>
            <!--<span class="inside_el_end_rodape"></span>-->
    
    </div>

</template>

<script>
export default {
    data() {
        return {
            liCall:['A','oportunidade','que','você','procurava','para','chamar','a','atenção','do','seu','cliente!']
        }
    }
}
</script>
