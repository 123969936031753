<style scoped>
    @import url("../../styles/contactos/contactos.scss");
</style>

<template>

    <div class="box_contactos" :style="{backgroundImage:'url(' + require('../../assets/imgsInit/capaPink4c.png') + ')'}">

        <!-- BTN BACK TO MAIN -->
        <div class="btnBack" @click="callRota('/')">
            <v-icon 
            large 
            @mouseover="colorBtnBack = '#F7D72E'" 
            @mouseleave="colorBtnBack = '#4B94C0'"
            :style="{'color':colorBtnBack}"
            >
                mdi-arrow-left-circle-outline
            </v-icon>
        </div>

        <!-- TIT -->
        <div class="box_tit_c">
            <span>Contactos</span>
        </div>

        <!-- CONTENT -->
        <div class="box_content_c">

            <div class="txt_c">
                <span class="mb-3" :style="{'color':'#4B94C0'}">{{ txt1 }}</span>
                <span class="mb-3" :style="{'color':'#4B94C0'}">{{ txt2 }}</span>

                <div class="iconsCallExt">
                    <v-icon class="mr-3" :style="{'color':'#092D4F'}" @click="callURL('facebook')">mdi-facebook</v-icon>
                    <v-icon :style="{'color':'#092D4F'}" @click="callURL('instagram')">mdi-instagram</v-icon>
                </div>
            </div>

            <!--<div class="img_QS">
                <v-img class="img_logoMenuApp" 
                :src="require('../../assets/imgsInit/loraQS_2.jpeg')" 
                :style="{'border-radius':'48px'}"
                />
            </div>-->

        </div>
    
    </div>

</template>

<script>
export default {
    
    data() {
        return {
            //
            colorBtnBack:'#4B94C0',

            txt1:'Telemóvel: +351 964 714 354',
            txt2:'E-mail: dayvid.leonardo@upinc.pt',
        }
    },

    methods:{
        
        callRota(rota) { this.$router.push(rota) },

        callURL(rota) {
            if(rota == 'facebook') {
                const FACEBOOK_URL = 'https://www.facebook.com/profile.php?id=61555688992758'
                window.open(FACEBOOK_URL)
            } else {
                const INSTAGRAM_URL = ''
                window.open(INSTAGRAM_URL)
            }
        },
    },

    created() { window.scrollTo(0,0) },
}
</script>
