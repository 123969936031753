<style scoped>
    @import url("../styles/menuApp.scss");
</style>

<template>

    <div class="box_menuApp">

        <div @click="callRota('/QuemSomos')"
        class="itemMenuApp_txt"
        @mouseover="color1 = 'black'"
        @mouseleave="color1 = '#4B94C0'"
        >
            <span :style="{'color':color1}">Quem somos</span>
        </div>

        <div class="itemMenuApp_logo ml-2 mr-2" @click="callRota('/')">
            <v-img class="img_logoMenuApp" :style="{'border-radius':'50px'}" :src="require('../assets/imgsInit/uplogo0.jpeg')" />
        </div>

        <div @click="callRota('/Contactos')"
        class="itemMenuApp_txt"
        @mouseover="color2 = 'black'"
        @mouseleave="color2 = '#4B94C0'"
        >
            <span :style="{'color':color2}">Contactos</span>
        </div>
    
    </div>

</template>

<script>
export default {
    data() {
        return {
            //
            color1:'#4B94C0',
            color2:'#4B94C0',
        }
    },

    methods:{
        callRota(rota) { this.$router.push(rota) }
    },
}
</script>
