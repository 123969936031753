<style scoped>
    @import url("../../styles/entrada/entrada.scss");
</style>

<template>

    <!-- :style="{backgroundImage:'url(' + require('../../assets/imgsInit/fundcapa.jpg') + ')'}" -->
    <div class="box_entrada" :style="{backgroundImage:'url(' + require('../../assets/imgsInit/capaPink4c.png') + ')'}">

        <CarouselEntrada />

        <PainelEntrada />
    
    </div>

</template>

<script>
import CarouselEntrada from './carousel/CarouselEntrada'
import PainelEntrada from './PainelEntrada'

export default {

    components:{ CarouselEntrada, PainelEntrada },

    data() {
        return {
            //
        }
    }
}
</script>
