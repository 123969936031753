<style scoped>
    @import url("../../styles/entrada/painelEntrada.scss");
</style>

<template>

    <div class="box_painelEntrada mb-2">

        <div class="box_titPainelEntrada">

            <span class="titPainelEntrada_s hidden-md-and-up mr-2">Produtos recentes</span>
            <span class="titPainelEntrada_l hidden-sm-and-down mr-2">Produtos recentes</span>

            <v-icon :style="{'color':bellColor}">mdi-bell-ring</v-icon>
        </div>

        <div class="exibProds">
            <div @click="callContacts('/Contactos')"
            class="cardProd" v-for="(el,i) in li" :key="i"
            @mouseover="el.shadowCard='0px 1px 2px black'"
            @mouseleave="el.shadowCard='0px 1px 12px #4B94C0'"
            :style="{'box-shadow':el.shadowCard}">

                <div class="box_imgCardProd">
                    <img 
                    class="imgCardProd"
                    :style="{'width':'100%','height':'100%'}"
                    :src="el.img"
                    >
                </div>

                <div class="txtCardProd">
                    <span class="mr-1">Saber mais</span>
                    <v-icon>mdi-arrow-expand</v-icon>
                </div>

            </div>
        </div>
    
    </div>

</template>

<script>
export default {
    
    data() {
        return {
            shadowCard:'0px 1px 12px #4B94C0',

            bellColor:'#F7D72E',
            flagBellColor:true,

            li:[
                {'nome':'Mini Led','img':require('../../assets/produtos/chavBen1.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Mini Led','img':require('../../assets/produtos/miniLedSpo0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Mini Led','img':require('../../assets/produtos/miniLedPor0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Mini Led','img':require('../../assets/produtos/miniLedFla0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Mini Led','img':require('../../assets/produtos/miniLedFlu0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Mini Led','img':require('../../assets/produtos/miniLedBen0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Mini Led','img':require('../../assets/produtos/miniLedSCC0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Chaveiro','img':require('../../assets/produtos/chavFla0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Chaveiro','img':require('../../assets/produtos/chavFlu0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Chaveiro','img':require('../../assets/produtos/chavSpo1.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Led neon','img':require('../../assets/produtos/caneca0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Led neon','img':require('../../assets/produtos/sucasa.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Letreiro neon','img':require('../../assets/produtos/mexican0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Letreiro neon','img':require('../../assets/produtos/tacos0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Letreiro neon','img':require('../../assets/produtos/tripleR_0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Letreiro neon','img':require('../../assets/produtos/fund0.jpg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Letreiro neon','img':require('../../assets/produtos/almma1.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Letreiro neon','img':require('../../assets/produtos/letr0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Led Neon','img':require('../../assets/produtos/fn0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Led Neon','img':require('../../assets/produtos/led0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Led Neon','img':require('../../assets/produtos/lora1.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
                {'nome':'Led Neon','img':require('../../assets/produtos/soco0.jpeg'),'shadowCard':'0px 1px 12px #4B94C0'},
            ],
        }
    },

    methods:{
        callContacts(rota) { this.$router.push(rota) },
    },

    created() { 
        window.scrollTo(0,0)

        setInterval(() => {
            
            this.flagBellColor = !this.flagBellColor

            if (this.flagBellColor) {
                this.bellColor = '#F7D72E'
            } else { this.bellColor = 'black' }

        },3000)
    },
}
</script>
